import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

const MONTHS  = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class Month {
  constructor(
    public name: string,
    public days: Day[]
  ) {}
}

class Day {
  constructor(
    public dow: number,
    public holiday: boolean
  ) {}
}


@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {

  year: number;
  months: Month[];
  dow = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  id
  occurrences: any;
  redDates: any;


  //rdvs vot etre recupéré par la suite selon la date du chaque jour 
  rdvs =  [ 
  { heure: "09:00", patient: "Foulen Ben Foulen", motif: "Motif de consultation 1" },
  { heure: "09:30", patient: "Emily Wilson", motif: "Nouveau motif 5" },
  { heure: "10:00", patient: "John Doe", motif: "Motif de consultation 2" },
  { heure: "10:30", patient: "Jane Smith", motif: "Motif de consultation 3" },
  { heure: "11:00", patient: "Frank Brown", motif: "Nouveau motif 6" },
  { heure: "11:30", patient: "Grace Lee", motif: "Nouveau motif 7" },
  { heure: "12:00", patient: "Alice Johnson", motif: "Nouveau motif 1" },
  { heure: "12:30", patient: "Hannah Martinez", motif: "Nouveau motif 8" },
  { heure: "13:00", patient: "Bob Brown", motif: "Nouveau motif 2" },
  { heure: "13:30", patient: "Isaac Taylor", motif: "Nouveau motif 9" },
  { heure: "14:00", patient: "Carol Williams", motif: "Nouveau motif 3" },
  { heure: "14:30", patient: "Jessica Anderson", motif: "Nouveau motif 10" },
  { heure: "15:00", patient: "David Miller", motif: "Nouveau motif 4" },
  { heure: "15:30", patient: "Kevin Rodriguez", motif: "Nouveau motif 11" }





]
  sizeContent: any;


  constructor () {
    this.setYear(2024);
  }

  ngOnInit(): void {
    this.sizeContent=this.rdvs.length

    this.occurrences =[
    {
        "_id": "65eedb14e644ecca191f9a20",
        "status": "Annulée",
        "name": "Dépoussiérage en hauteur",
        "date": "2024-03-01",
        "benefit": "65c3590e1b294d42bc772534",
        "site": "65a93dc75fdfa830d12239e2",
        "createdAt": "2024-03-11T10:21:08.710Z",
        "updatedAt": "2024-04-19T07:57:45.140Z",
        "__v": 0
    },
    {
        "_id": "65eedb14e644ecca191f9a21",
        "status": "Modifiée",
        "name": "Dépoussiérage en hauteur",
        "date": "2024-05-09",
        "benefit": "65c3590e1b294d42bc772534",
        "site": "65a93dc75fdfa830d12239e2",
        "createdAt": "2024-03-11T10:21:08.712Z",
        "updatedAt": "2024-05-05T12:02:01.054Z",
        "__v": 0
    },
    {
        "_id": "65eedb14e644ecca191f9a22",
        "status": "Planifiée",
        "name": "Dépoussiérage en hauteur",
        "date": "2024-09-01",
        "benefit": "65c3590e1b294d42bc772534",
        "site": "65a93dc75fdfa830d12239e2",
        "createdAt": "2024-03-11T10:21:08.714Z",
        "updatedAt": "2024-03-11T10:21:08.714Z",
        "__v": 0
    },
    {
        "_id": "65eedb14e644ecca191f9a23",
        "status": "Planifiée",
        "name": "Dépoussiérage en hauteur",
        "date": "2024-12-01",
        "benefit": "65c3590e1b294d42bc772534",
        "site": "65a93dc75fdfa830d12239e2",
        "createdAt": "2024-03-11T10:21:08.715Z",
        "updatedAt": "2024-03-11T10:21:08.715Z",
        "__v": 0
    },
    {
        "_id": "65eedb14e644ecca191f9a28",
        "status": "Planifiée",
        "name": "Dépoussiérage en hauteur",
        "date": "2025-03-01",
        "benefit": "65c3590e1b294d42bc772534",
        "site": "65a93dc75fdfa830d12239e2",
        "createdAt": "2024-03-11T10:21:08.717Z",
        "updatedAt": "2024-03-11T10:21:08.717Z",
        "__v": 0
    },
    {
        "_id": "65eedb14e644ecca191f9a29",
        "status": "Planifiée",
        "name": "Dépoussiérage en hauteur",
        "date": "2025-06-01",
        "benefit": "65c3590e1b294d42bc772534",
        "site": "65a93dc75fdfa830d12239e2",
        "createdAt": "2024-03-11T10:21:08.718Z",
        "updatedAt": "2024-03-11T10:21:08.718Z",
        "__v": 0
    },
    {
        "_id": "65eedb14e644ecca191f9a2a",
        "status": "Planifiée",
        "name": "Dépoussiérage en hauteur",
        "date": "2025-09-01",
        "benefit": "65c3590e1b294d42bc772534",
        "site": "65a93dc75fdfa830d12239e2",
        "createdAt": "2024-03-11T10:21:08.719Z",
        "updatedAt": "2024-03-11T10:21:08.719Z",
        "__v": 0
    },
    {
        "_id": "65eedb14e644ecca191f9a2b",
        "status": "Planifiée",
        "name": "Dépoussiérage en hauteur",
        "date": "2025-12-01",
        "benefit": "65c3590e1b294d42bc772534",
        "site": "65a93dc75fdfa830d12239e2",
        "createdAt": "2024-03-11T10:21:08.720Z",
        "updatedAt": "2024-03-11T10:21:08.720Z",
        "__v": 0
    },
    {
        "_id": "65eedb14e644ecca191f9a30",
        "status": "Planifiée",
        "name": "Dépoussiérage en hauteur",
        "date": "2026-03-01",
        "benefit": "65c3590e1b294d42bc772534",
        "site": "65a93dc75fdfa830d12239e2",
        "createdAt": "2024-03-11T10:21:08.722Z",
        "updatedAt": "2024-03-11T10:21:08.722Z",
        "__v": 0
    }
]
this.redDates = this.occurrences.map(item => item.date);

  }

  setYear(year: number) {
    this.year = year;
    this.months = MONTHS.map( m => {
      // Get starting day of week of month
      var month = new Date(`${m} 1 ${this.year}`);
      var dow = month.getDay();
      dow = (dow + 6) % 7;

      // Get list of days in this month
      month.setMonth(month.getMonth() + 1);
      month.setDate(month.getDate() - 1);
      var days = Array.from(Array(month.getDate()).keys())
      
      // Each month has name and list of days
      return new Month(
        m, days.map( d => {
          // Each day randomly has holiday for test data
          return new Day(
            (dow + d) % 7,
            Math.random() >= 0.5
        )}
      ));
    })
  }

  getPadding(month: Month) {
    return {
      'flex-basis': `calc(100% * (${month.days[0].dow} / 7))`
    };
  }

  clickDay(month: Month, d: number) {
    var day = month.days[d];
    console.log(`You clicked ${this.dow[day.dow]} ${month.name} ${d+1}, ${this.year} (${day.holiday ? 'Holiday' : 'Workday'})`)
  }
  // Add a function to determine the color for a specific date
  getCssClassForDate(month: Month, dayIndex: number): string {
    const clickedDate = new Date(this.year, MONTHS.indexOf(month.name), dayIndex + 1);
  
    if (!Array.isArray(this.redDates)) {
      // Handle the case where this.redDates is not an array
      return month.days[dayIndex].holiday ? 'red' : 'black';
    }
  
    // Convert redDates to an array of date strings in the format 'yyyy-MM-dd'
    const redDateStrings = this.redDates.map(date => {
      const dateObj = new Date(date);
      dateObj.setHours(0, 0, 0, 0); // Set time to midnight
      return dateObj.toISOString().slice(0, 10);
    });
  
    const clickedDateMidnight = new Date(clickedDate);
    clickedDateMidnight.setHours(0, 0, 0, 0); // Set time to midnight
    const clickedDateString = clickedDateMidnight.toISOString().slice(0, 10);
  
    if (redDateStrings.includes(clickedDateString)) {
      return 'highlighted-date';
    } else {
      return month.days[dayIndex].holiday ? 'red' : 'black';
    }
  }
}
