import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PatientComponent } from './patient/patient.component';
import { MedecinComponent } from './medecin/medecin.component';
import { AddPatientComponent } from './patient/add-patient/add-patient.component';
import { UpdatePatientComponent } from './patient/update-patient/update-patient.component';
import { ConclusionComponent } from './consultation/conclusion/conclusion.component';
import { ConduitATenirComponent } from './consultation/conduit-atenir/conduit-atenir.component';
import { CertificatComponent } from './consultation/certificat/certificat.component';
import { SearchMedecinComponent } from './search-medecin/search-medecin.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/authGuards/auth.guard';
import { ConsultationListComponent } from './consultation/consultation-list/consultation-list.component';
import { ProfileComponent } from './profile/profile.component';
import { AjoutDocumentPatientComponent } from './documents/ajout-document-patient/ajout-document-patient.component';
import { UpdateDocumentPatientComponent } from './documents/update-document-patient/update-document-patient.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AddRdvComponent } from './rdvs/add-rdv/add-rdv.component';
import { RdvsComponent } from './rdvs/rdvs.component';
import { CalendrierComponent } from './calendar/calendar.component';


const routes: Routes = [
  { path: '', redirectTo: 'medecin/accueil', pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'medecin/accueil', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'medecin/agenda', component: CalendrierComponent, canActivate: [AuthGuard] },
  { path: 'medecin/patients-liste', component: PatientComponent, canActivate: [AuthGuard] },
  { path: 'medecin/medecins', component: MedecinComponent, canActivate: [AuthGuard] },
  { path: 'medecin/recherche-medecins', component: SearchMedecinComponent, canActivate: [AuthGuard] },
  { path: 'medecin/nouveauPatient', component: AddPatientComponent, canActivate: [AuthGuard] },
  { path: 'medecin/patient/:id', component: UpdatePatientComponent, canActivate: [AuthGuard] },
  { path: 'medecin/conclusion', component: ConclusionComponent, canActivate: [AuthGuard] },
  { path: 'medecin/ConduitAtenir', component: ConduitATenirComponent, canActivate: [AuthGuard] },
  { path: 'medecin/certificat', component: CertificatComponent, canActivate: [AuthGuard] },
  { path: 'medecin/consultation-liste/:id', component: ConsultationListComponent, canActivate: [AuthGuard] },
  { path: 'medecin/profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'medecin/ajout/nouveaux/documents/:id', component: AjoutDocumentPatientComponent, canActivate: [AuthGuard] },
  { path: 'medecin/document/patient/modifier/:id/:idDocument', component: UpdateDocumentPatientComponent, canActivate: [AuthGuard] },
  { path: 'medecin/rendez-vous', component: RdvsComponent, canActivate: [AuthGuard] },
  { path: 'medecin/ajout-rdv', component: AddRdvComponent, canActivate: [AuthGuard] },
  { path: 'medecin/not-found', component: NotFoundComponent },
  { path: 'redirect', component: LoginComponent },
  { path: '**', redirectTo: '/medecin/accueil' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
