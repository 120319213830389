<app-header></app-header>

<div class="container-fluid p-sm-5 bg-light">
    <div class="year-controls">
        <button class="btn btn-secondary button_list mb-2" (click)="setYear(year - 1)">Précédent</button>
        <span class="year-label">{{year}}</span>
        <button class="btn btn-primary button_list mb-2" (click)="setYear(year + 1)">Suivant</button>
    </div>
    <br>
    <br>
    <div class="calendar row">
        <div *ngFor="let month of months" class="month col-sm-6 col-md-4 col-lg-3 mb-4">
            <div *ngIf="month.name == 'Jan' " class="calendar-header p-2">Janvier</div>
            <div *ngIf="month.name == 'Feb' " class="calendar-header p-2">Février</div>
            <div *ngIf="month.name == 'Mar' " class="calendar-header p-2">Mars </div>
            <div *ngIf="month.name == 'Apr' " class="calendar-header p-2">Avril </div>
            <div *ngIf="month.name == 'May' " class="calendar-header p-2">Mai </div>
            <div *ngIf="month.name == 'Jun' " class="calendar-header p-2">Juin </div>
            <div *ngIf="month.name == 'Jul' " class="calendar-header p-2">Juillet </div>
            <div *ngIf="month.name == 'Aug' " class="calendar-header p-2">Août </div>
            <div *ngIf="month.name == 'Sep' " class="calendar-header p-2">Septembre </div>
            <div *ngIf="month.name == 'Oct' " class="calendar-header p-2">Octobre </div>
            <div *ngIf="month.name == 'Nov' " class="calendar-header p-2">Novembre</div>
            <div *ngIf="month.name == 'Dec' " class="calendar-header p-2">Décembre </div>


            <div class="calendar-content d-flex flex-wrap">
                <!-- Day of week labels -->
                <div *ngFor="let day of dow" class="col calendar-day"><strong style="color: #21726B;">{{day[0]}}</strong></div>
                <div class="calendar-day" [ngStyle]="getPadding(month)"></div>
                <div *ngFor="let day of month.days; let d = index" class="calendar-day"
                    [ngClass]="getCssClassForDate(month, d)">
                    <button style="border: none !important;" data-toggle="modal" data-target="#exampleModalArchiver"
                        (click)="clickDay(month, d)">{{d+1}}</button>
                </div>
                <!-- Ensure every month is 6 rows -->
                <!-- <div *ngIf="month.days[0].dow + month.days.length < 36" class="calendar-day" style="flex-basis: 100%"></div> -->
                <!-- Exception if Feb has 28 days and last day falls on a Saturday (see: 2037) -->
                <!-- <div *ngIf="month.days[0].dow + month.days.length < 29" class="calendar-day" style="flex-basis: 100%"></div> -->
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>


<!--modal-->
<div

  class="modal fade"
  id="exampleModalArchiver"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 style="color: #44bdb3;"  class="modal-title" id="exampleModalLabel">
          Mes Rendez-vous
        </h4> 
        <!-- <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button> -->
        <a class="exit" aria-label="Close" data-dismiss="modal" class="close exit"><i class="fa-regular fa-circle-xmark"></i></a>
      </div>
      <div class="modal-body">
        <div [ngClass]="sizeContent > 8 ? 'scroller' : 'scroller2'" class="modal-body">
          <table class="table">
            <thead>
                <tr class="ligne-paire">
                    <th class="text-center" style="color:#589590;">Heure</th>
                    <th class="text-center" style="color:#589590;">Patient</th>
                    <th class="text-center" style="color:#589590;">Motif</th>
                </tr>
            </thead>
            <tbody *ngFor="let rdv of rdvs ">
                <tr class="ligne-impaire">
                    <td class="text-center">{{rdv.heure}}</td>
                    <td class="text-center">{{rdv.patient}}</td>
                    <td class="text-center">{{rdv.motif}}</td>
                    <!-- Ajoutez d'autres cellules au besoin -->
                </tr>
            </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>
</div>